import React from "react"
import { Box, Text } from "theme-ui"
import SEO from "../../../components/SEO"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import ContentSection from "../../../components/ContentSection"

const classroomCourse = () => {
  return (
    <Box>
      <SEO title="classroomcourse" />
      <Layout>
        <PageHeader>
          <Text
            sx={{ fontSize: [3, 5], fontWeight: "bold", textAlign: "center" }}
          >
            Classroom Course
          </Text>
        </PageHeader>
        <ContentSection
          header="Classroom Course"
          body={
            <Box
              sx={{
                maxWidth: "800px",
                marginTop: ["-50px", "auto"],
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "auto",
                textAlign: "justify",
              }}
            >
              <Text sx={{ fontSize: [0, 3] }}>
                CLASS ROOM COURSES FOR UPSC(UNION PUBLIC SERVICE COMMISION)
                EXAMS TYPICALLY INVOLVE STRUCTURED IN PERSON TEACHING SESSIONS
                CONDUCTED BY EXPERIENCED FACULTY. THESE COURSES AIM TO PREPARE
                CANDIDATES FOR THE CIVIL SERVICES EXAMINATION, WHICH INCLUDES
                THE IAS(INDIAN ADMINISTRATIVE SERVICE), IPS(INDIAN POLICE
                SERVICE) AND IFS (INDIAN FOREIGN SERVICE) AMONG OTHERS. KEY
                FEATURES OF CLASSROOM COURSES FOR UPSC INCLUDE:
              </Text>

              <Text sx={{ fontSize: [0, 3] }} my={4}>
                1. <span style={{ fontWeight: "800" }}>EXPERT GUIDANCE: </span>
                EXPERIENCED INSTRUTORS PROVIDE COMPREHENSIVE COVERAGE OF UPSC
                SYLLABUS TOPICS, SHARE INSIGHTS, AND GUIDE STUDENTS IN EXAM
                PREPARATION STRATEGIES.
              </Text>

              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                2.{" "}
                <span style={{ fontWeight: "800" }}>
                  INTERACTIVE LEARNING:{" "}
                </span>{" "}
                CLASSROOM SETTINGS ALLOW FOR SREAL TIMEINTERACTION. Q&A
                SESSIONS, AN DISCSSIONS, FOSTERING A BETTER UNDERSTANDING OF
                COMPLEX SUBJECTS.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                3.{" "}
                <span style={{ fontWeight: "800" }}>
                  STRUCTURED CURRICULUM:{" "}
                </span>
                COURSES ARE ORGANISZED TO COVER THE ENTIRE UPSC SYLLABUS,
                ENSURING THAT CANDIDATES RCEIVE A WELL ROUNDED PREPARATIN FOR
                THE PRELIMS, MAINS, AND INTERVIEW STAGES.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                4. <span style={{ fontWeight: "800" }}>STUDY MATERIAL:</span> A
                STUDENTS OFTEN RECEIVE STUDY MATERIALS, NOTES, AND RESOURCES TO
                COMPLEMENT THEIR CLASSROOM LEARNING AND AID SELF STUDY.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                5.{" "}
                <span style={{ fontWeight: "800" }}>
                  MOCK TESTS AND EVALUATION:{" "}
                </span>{" "}
                REGULAR MOCK TESTS AND PRACTICE SESSIONS ARE CONDUCTED TO
                SIMULATE EXAM CONDITIONS, HELPING CANDIDATES ASSESS THEIR
                PROGRSS AND IDENTIFY AREAS FOR IMPROVEMENT.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                6. <span style={{ fontWeight: "800" }}> PEER LEARNING:</span>{" "}
                CLASSROOM ENVIRONMENTS FACILITATE INTERACTON WITH FELLOW
                ASPIRANTS ALLOWING THE EXCHANGE OF IDEA, STUDY TECHNIQUES, AND
                MTUAL SUPPORT.
              </Text>
              <Text sx={{ fontSize: [0, 3] }} mt={4}>
                7.{" "}
                <span style={{ fontWeight: "800" }}>
                  MOTIVATION AND DISCIPLINE:{" "}
                </span>{" "}
                THE STRUCTURED NATRE OF CLASSROOM COURSES CAN INSTILL DISCIPLIEN
                IN STUDY ROUTINES AND PROVIDE ASPIRANTS WITHS THE MOTIVATION
                NEEDED FOR A RIGOROUS EXAM LIKE UPSC.
              </Text>
             
              
            </Box>
          }
        />
      </Layout>
    </Box>
  )
}
export default classroomCourse
